<template>
    <div class="countymap-box">
        <baidu-map
            v-if="!loading"
            :max-zoom="12"
            :min-zoom="10"
            :center="centers"
            :zoom="11"
            @ready="handler"
            :scroll-wheel-zoom="true"
            :mapStyle="mapStyles"
            class="bm-view"
        >
        </baidu-map>

        <Modal v-model="modal" :title="modalTitle" footer-hide width="65%">
            <div style="position: relative; text-align: center; height: 360px">
                <Tabs size="small" type="card" value="name1">
                    <TabPane label="学校最新入库" name="name1">
                        <LookMore
                            :seamlesstitle="inListTitle"
                            :seamlessbody="inListData"
                            heightBody="220px"
                        ></LookMore>
                    </TabPane>
                    <TabPane label="学校最新出库" name="name2">
                        <LookMore
                            :seamlesstitle="outListTitle"
                            :seamlessbody="outListData"
                            heightBody="220px"
                        ></LookMore
                    ></TabPane>
                    <TabPane label="获取最新价格" name="name3">
                        <LookMore
                            :seamlesstitle="priceListTitle"
                            :seamlessbody="priceListData"
                            heightBody="220px"
                        >
                        <template  v-slot:pic="record">
                 <img  v-if="record.item.picture" style="color: #56FEFE;height: 80px;" :src="record.item.picture"></img>
                 <span v-else>--</span>
             </template>
                      </LookMore>
                    </TabPane>
                    <!-- <TabPane label="入库订单详情" name="name4"
                        >获取最新价格</TabPane
                    > -->
                    <!-- <TabPane label="出库订单详情" name="name5"
                        >获取最新价格</TabPane
                    > -->
                </Tabs>
            </div>
        </Modal>
    </div>
</template>
<script>
import axios from "axios";
import {
    schoolApi,
    inApi,
    outApi,
    priceApi,
    inDetailApi,
    outDetailApi,
} from "@/api";
import LookMore from "@/components/lookMore/index.vue";

export default {
    name: "countymap",
    components: {
        LookMore,
    },
    props: {},
    data() {
        return {
            modal: false,
            loading: false,
            modalTitle: "",
            centers: { lng: "", lat: "" },
            zooms: 11,
            mapStyles: {
                styleJson: [
                    {
                        featureType: "water",
                        elementType: "all",
                        stylers: {
                            color: "#044161",
                        },
                    },
                    {
                        featureType: "land",
                        elementType: "all",
                        stylers: {
                            color: "#091934",
                        },
                    },
                    {
                        featureType: "boundary",
                        elementType: "geometry",
                        stylers: {
                            color: "#064f85",
                        },
                    },
                    {
                        featureType: "railway",
                        elementType: "all",
                        stylers: {
                            visibility: "off",
                        },
                    },
                    {
                        featureType: "highway",
                        elementType: "geometry",
                        stylers: {
                            color: "#004981",
                            visibility: "off", // 不显示高速公路
                        },
                    },
                    {
                        featureType: "highway",
                        elementType: "geometry.fill",
                        stylers: {
                            color: "#030C66",
                            lightness: 1,
                        },
                    },
                    {
                        featureType: "highway",
                        elementType: "labels",
                        stylers: {
                            visibility: "on",
                        },
                    },
                    {
                        featureType: "arterial",
                        elementType: "geometry",
                        stylers: {
                            color: "#004981",
                            lightness: -39,
                        },
                    },
                    {
                        featureType: "arterial",
                        elementType: "geometry.fill",
                        stylers: {
                            color: "#00508b",
                        },
                    },
                    {
                        featureType: "poi",
                        elementType: "all",
                        stylers: {
                            visibility: "on",
                        },
                    },
                    {
                        featureType: "green",
                        elementType: "all",
                        stylers: {
                            color: "#056197",
                            visibility: "on",
                        },
                    },
                    {
                        featureType: "subway",
                        elementType: "all",
                        stylers: {
                            visibility: "on",
                        },
                    },
                    {
                        featureType: "manmade",
                        elementType: "all",
                        stylers: {
                            visibility: "on",
                        },
                    },
                    {
                        featureType: "local",
                        elementType: "all",
                        stylers: {
                            visibility: "on",
                        },
                    },
                    {
                        featureType: "arterial",
                        elementType: "labels",
                        stylers: {
                            visibility: "on",
                        },
                    },
                    {
                        featureType: "boundary",
                        elementType: "geometry.fill",
                        stylers: {
                            color: "#029fd4",
                        },
                    },
                    {
                        featureType: "building",
                        elementType: "all",
                        stylers: {
                            color: "#1a5787",
                        },
                    },
                    {
                        featureType: "label",
                        elementType: "all",
                        stylers: {
                            visibility: "on",
                        },
                    },
                    {
                        featureType: "poi",
                        elementType: "labels.text.fill",
                        stylers: {
                            color: "#ffffff",
                        },
                    },
                    {
                        featureType: "poi",
                        elementType: "labels.text.stroke",
                        stylers: {
                            color: "#1e1c1c",
                        },
                    },
                    {
                        featureType: "administrative",
                        elementType: "labels",
                        stylers: {
                            visibility: "on",
                        },
                    },
                    {
                        featureType: "road",
                        elementType: "labels",
                        stylers: {
                            visibility: "off",
                        },
                    },
                ],
            },
            points: [],
            inListTitle: [
                {
                    title: "订单编号",
                    styles: { width: "20%" },
                    textcolor: "",
                    key: "order_code",
                },
                {
                    title: "订单类型",
                    textcolor: "",
                    key: "order_type_name",
                    styles: { width: "20%" },
                },

                {
                    title: "发货金额",
                    textcolor: "#1E97DB",
                    key: "shipping_money",
                    styles: { width: "20%" },
                },
                {
                    title: "收货金额",
                    key: "reciver_money",
                    styles: { width: "20%" },
                },
                {
                    title: "发货日期",
                    key: "shipping_date",
                    styles: { width: "20%" },
                },
            ],
            inListData: [],

            outListTitle: [
                {
                    title: "订单类型",
                    textcolor: "",
                    key: "order_type_name",
                    styles: { width: "25%" },
                },
                {
                    title: "出库数量",
                    textcolor: "",
                    key: "out_num",
                    styles: { width: "25%" },
                },

                {
                    title: "出库金额",
                    textcolor: "#1E97DB",
                    key: "out_money",
                    styles: { width: "25%" },
                },
                {
                    title: "出库日期",
                    textcolor: "#1E97DB",
                    key: "out_date",
                    styles: { width: "25%" },
                },
            ],
            outListData: [],

            priceListTitle: [
                {
                    title: "",
                    styles: { width: "20%" },
                    textcolor: "",
                    slot: "pic",
                },
                {
                    title: "食材",
                    styles: { width: "20%" },
                    textcolor: "",
                    key: "product_name",
                },
                {
                    title: "价格",
                    textcolor: "",
                    key: "price",
                    styles: { width: "20%" },
                },
                {
                    title: "单位",
                    textcolor: "",
                    key: "unit",
                    styles: { width: "20%" },
                },
                {
                    title: "分类",
                    textcolor: "",
                    key: "cate_name",
                    styles: { width: "20%" },
                },
            ],
            priceListData: [],
            entity_id: "",
        };
    },
    created() {
        this.initData();
    },
    methods: {
        handelModelChange() {},
        async getInListData() {
            try {
                this.loading = true;
                const resData = await inApi({
                    entity_id: this.entity_id,
                });
                this.inListData = resData.data;

                const outData = await outApi({
                    entity_id: this.entity_id,
                });
                this.outListData = outData.data;

                const resPrice = await priceApi({
                    entity_id: this.entity_id,
                    page:1,
                    limit:50

                });
                this.priceListData = resPrice.data;
                // const resDetail = await inDetailApi({
                //    entity_id:this.entity_id
                // });
                // const resOutDetail = await outDetailApi({
                //    entity_id:this.entity_id
                // });
            } finally {
                this.loading = false;
            }
        },
        async initData() {
            try {
                this.loading = true;
                const resData = await schoolApi({
                    area_id: this.$route.query.area_id,
                });
                this.points = resData.data.map((item) => {
                    return {
                        lng: item.longitude,
                        lat: item.latitude,
                        label: item.name,
                        id: item.id,
                    };
                });
            } finally {
                this.loading = false;
            }
        },
        getBoundarys(BMap, map, address) {
            var boundary = new BMap.Boundary();
            boundary.get(address, function (rs) {
                var count = rs.boundaries.length;
                for (var i = 0; i < count; i++) {
                    var boundariesArray = rs.boundaries[i];
                    var ply = new BMap.Polygon(boundariesArray, {
                        strokeWeight: 3,
                        strokeColor: "#00FDFB",
                        strokeStyle: "solid",
                        fillColor: "#2F02F5",
                        fillColorOpacity: "0.3",
                    });
                    map.addOverlay(ply);
                    // map.setViewport(ply.getPath());
                    // map.setZoom(map.getZoom());
                    // map.setViewport(ply.getPath());
                }
            });
        },
        addMarker(pointsArr, n, BMap, map) {
            let _this = this;
            for (let i = 0; i < pointsArr.length; i++) {
                let point = new BMap.Point(pointsArr[i].lng, pointsArr[i].lat);
                let myIcon = new BMap.Icon(
                    require("@/assets/images/dot" + n + ".svg"),
                    new BMap.Size(30, 30)
                );
                let marker = new BMap.Marker(point, { icon: myIcon });
                let labelHtml =
                    '<div class="label-box"><div class="label-title">' +
                    pointsArr[i].label +
                    "</div></div>";
                map.addOverlay(marker);
                let label = new BMap.Label(labelHtml, {
                    offset: new BMap.Size(10, -10),
                });
                label.setStyle({
                    fontSize: "14px",
                    backgroundColor: "rgba(0,202,249,0)",
                    padding: "4px",
                    border: "1px solid rgba(0,202,249,0)",
                });
                label.addEventListener("click", function () {
                    _this.modal = true;
                    _this.modalTitle = pointsArr[i].label;
                    _this.entity_id = pointsArr[i].id;
                    _this.getInListData();
                });
                marker.setLabel(label);
            }
        },
        handler({ BMap, map }) {
            axios
                .get(
                    `/hbJson/${this.$route.query.parentCode}.geoJson`
                )
                .then((response) => {
                    if (response.data) {
                        const findObj = response.data.features.find(
                            (item) =>
                                item.properties.adcode ==
                                this.$route.query.areaCode
                        );
                        const propertiesData = findObj.properties;
                        if(propertiesData.center&&propertiesData.center.length==2){
                            this.centers={
                            lng: propertiesData.center[0], lat:propertiesData.center[1]
                        }
                        }
                      
                        this.getBoundarys(BMap, map, propertiesData.name);
                        this.addMarker(this.points, 1, BMap, map);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
};
</script>
<style type="text/css" scoped>
.bm-view {
    width: 100%;
    height: 100%;
}

::v-deep .ivu-modal-header {
    border-bottom: none;
}
</style>
